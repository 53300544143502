<template>
  <div class="special-list-pc">
    <pc-header></pc-header>
    <div class="main">
      <div class="title">
        <p>
          专题课程<span>{{ "共" + count + "个专题" }}</span>
        </p>
      </div>
      <div
        class="special-item"
        v-for="(item, i) in dataList"
        :key="i"
        @click="toSpecial(item.id)"
      >
        <div class="left">
          <img :src="item.thematicCover" alt="" />
        </div>
        <div class="right">
          <p class="item-title hidden1">{{ item.topicName }}</p>
          <p class="item-info hidden4">{{ item.briefIntroduction }}</p>
        </div>
      </div>
      <div class="pagination">
        <div class="page-item left" @click="pageUp()">
          <img src="../../assets/pageLeft.png" alt="" />
        </div>
        <div
          :class="['page-item', 'page', page == item ? 'active' : '']"
          v-for="(item, i) in pages"
          :key="i"
          @click="pagination(item)"
        >
          {{ item }}
        </div>
        <div class="page-item right" @click="pageDn()">
          <img src="../../assets/pageRight.png" alt="" />
        </div>
      </div>
    </div>
    <pc-footer></pc-footer>
  </div>
</template>

<script>
import PcHeader from "../../components/pc-header.vue";
import PcFooter from "../../components/pc-footer.vue";
import { specialListPage } from "../../api/special";
export default {
  components: {
    PcHeader,
    PcFooter,
  },
  data() {
    return {
      count: 0,
      dataList: [],
      page: 1,
      size: 5,
      pages: 1,
    };
  },
  created() {
    this.getSpecialListPage();
  },
  methods: {
    pageUp() {
      if (this.page > 1) {
        this.page--;
        this.getSpecialListPage();
      }
    },
    pageDn() {
      if (this.page < this.pages) {
        this.page++;
        this.getSpecialListPage();
      }
    },
    pagination(page) {
      if (page != this.page) {
        this.page = page;
        this.getSpecialListPage();
      }
    },
    getSpecialListPage() {
      specialListPage({
        key: "",
        page: this.page,
        size: this.size,
        state: 1,
      }).then((res) => {
        this.dataList = res.data;
        this.count = res.count;
        this.page = res.page;
        var temp = res.count / this.size;
        var temp2 = parseInt(temp);
        if (temp > temp2) {
          this.pages = temp2 + 1;
        } else {
          this.pages = temp2;
        }
        console.log(this.pages);
      });
    },
    toSpecial(id) {
      this.$router.push({ path: "/special/" + id });
    },
  },
};
</script>

<style lang="scss" scoped>
.special-list-pc {
  height: 100%;
  background: url("../../assets/pcbg.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    min-height: 500px;
    width: 1200px;
    margin: 0 auto;
    .title {
      display: flex;
      height: 100px;
      line-height: 100px;
      color: #fff;
      p {
        margin: 0;
        font-size: 22px;
        span {
          font-size: 16px;
          margin-left: 8px;
        }
      }
    }
    .special-item {
      box-sizing: border-box;
      padding: 25px 55px 30px 30px;
      background-color: #fff;
      height: 220px;
      margin-bottom: 20px;
      display: flex;
      cursor: pointer;
      .left {
        flex-shrink: 0;
        width: 370px;
        height: 165px;
        border-radius: 8px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 695px;
        padding-left: 50px;
        p {
          margin: 0;
        }
        .item-title {
          height: 60px;
          line-height: 60px;
          font-size: 24px;
          color: #1872bb;
          font-weight: 600;
        }
        .item-info {
          color: #999;
          font-size: 16px;
          text-align: justify;
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin: 50px 0 110px;
      .page-item {
        width: 45px;
        height: 45px;
        border: 1px solid #ccc;
        margin: 0 10px;
        text-align: center;
        line-height: 45px;
        font-weight: bold;
        cursor: pointer;
        img {
          display: inline-block;
        }
      }
      .active {
        background: #49b67b;
        color: #fff;
        border-color: #49b67b;
      }
    }
  }
}
</style>
