<template>
  <div class="special-list-mob">
    <mob-header></mob-header>
    <div class="main">
      <div class="title">
        <p>
          专题课程<span>{{ "共" + dataList.length + "个专题" }}</span>
        </p>
      </div>
      <div
        class="special-item"
        v-for="(item, i) in dataList"
        :key="i"
        @click="toSpecial(item.id)"
      >
        <div class="left">
          <img :src="item.thematicCover" alt="" />
        </div>
        <div class="right">
          <p class="item-title hidden1">{{ item.topicName }}</p>
          <p class="item-info hidden3">{{ item.briefIntroduction }}</p>
        </div>
      </div>
    </div>
    <mob-footer-bar :active="1"></mob-footer-bar>
  </div>
</template>

<script>
import MobHeader from "../../components/mob-header.vue";
import MobFooterBar from "../../components/mob-footer-bar.vue";
import { specialList } from "../../api/special";
export default {
  components: {
    MobHeader,
    MobFooterBar,
  },
  data() {
    return {
      dataList: [],
    };
  },
  created() {
    this.getSpecialList();
  },
  methods: {
    // 专题列表
    getSpecialList() {
      specialList().then((res) => {
        this.dataList = res.data;
      });
    },
    toSpecial(id) {
      this.$router.push({ path: "/special/" + id });
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.special-list-mob {
  height: 100%;
  background: url("../../assets/mobbgsmall.png") 0 0 no-repeat;
  background-size: 100% auto;
  .main {
    padding: 0 0.1rem 0.8rem;
    .title {
      color: #fff;
      font-size: 0.16rem;
      span {
        font-size: 0.14rem;
        margin-left: 0.08rem;
      }
    }
    .special-item {
      display: flex;
      box-sizing: border-box;
      margin-top: 0.1rem;
      height: 0.96rem;
      padding: 0.1rem;
      background-color: #fff;
      .left {
        flex-shrink: 0;
        height: 0.76rem;
        width: 1.7rem;
        border-radius: 0.06rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        width: 1.53rem;
        padding-left: 0.12rem;
        .item-title {
          width: 1.53rem;
          font-size: 0.16rem;
          font-weight: bold;
        }
        .item-info {
          font-size: 0.12rem;
          color: #999;
        }
      }
    }
  }
}
</style>
