<template>
  <div class="page-view">
    <special-list-mob v-if="isMobile"></special-list-mob>
    <special-list-pc v-else></special-list-pc>
  </div>
</template>

<script>
import SpecialListPc from "./special-list-pc.vue";
import SpecialListMob from "./special-list-mob.vue";
export default {
  components: {
    SpecialListPc,
    SpecialListMob,
  },
  metaInfo: {
    title: "因美纳讲堂 | 专题",
  },
  data() {
    return {
      isMobile: this.$isMobile(),
    };
  },
};
</script>
